import http from "../utils/http";
let resquest = "/apis";
// 查询角色列表
export function roleList(params) {
  return http.post(`${resquest}/cusRole/list`, params);
}

// 删除角色
export function delRoleById(params) {
  return http.post(`${resquest}/cusRole/remove`, params);
}
// 新增角色
export function addRole(params) {
  return http.post(`${resquest}/cusRole/add`, params);
}
// 编辑角色
export function editRole(params) {
  return http.post(`${resquest}/cusRole/edit`, params);
}
// 菜单列表
export function listRole(params) {
  return http.post(`${resquest}/CusMenu/003-list`, params);
}
