import http from "../utils/http";
let resquest = "/apis";
// 查询用户列表
export function userList(params) {
  return http.post(`${resquest}/cusUser/list`, params);
}
// 删除用户
export function delUserById(params) {
  return http.post(`${resquest}/cusUser/remove`, params);
}
// 新增用户
export function addUser(params) {
  return http.post(`${resquest}/cusUser/add`, params);
}
// 编辑用户
export function editUser(params) {
  return http.post(`${resquest}/cusUser/edit`, params);
}
// 锁定用户
export function lockUser(params) {
  return http.post(`${resquest}/cusUser/lock`, params);
}
// 解锁用户
export function unLockUser(params) {
  return http.post(`${resquest}/cusUser/unLock`, params);
}
