<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="pub-tt m15">
          <h5 class="bt">
            <img
              src="../assets/images/equip_01.png"
              class="ico"
              alt=""
            />用户管理
          </h5>
          <div class="dix"></div>
        </div>
        <div class="top-bar p025">
          <el-form ref="form" class="top-form" :model="form" label-width="70px">
            <div class="flex">
              <div class="flex-1">
                <el-row :gutter="10">
                  <el-col :span="6">
                    <el-form-item label="用户名">
                      <el-input
                        v-model="form.name"
                        placeholder="请输入用户名"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="角色">
                      <el-select v-model="form.roleId" placeholder="请选择角色">
                        <el-option
                          v-for="(item, index) in roleOption"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                        <!-- <el-option label="张三丰1" value="1"></el-option>
                        <el-option label="张三丰2" value="2"></el-option> -->
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="手机号">
                      <el-input
                        v-model="form.phone"
                        placeholder="请输入手机号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="状态">
                      <el-select v-model="form.status" placeholder="请选择状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="锁定" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <button
                class="ann annbg1"
                style="margin-left: 20px"
                @click.prevent="getList"
              >
                搜索
              </button>
            </div>
          </el-form>
        </div>
        <div class="btn-bar p025">
          <button class="anniu add" @click="handleAdd">新增用户</button>
          <!-- <button class="anniu role" @click="addRolePop = true">
            角色管理
          </button> -->
        </div>
        <div class="flex-1 flex-v p025">
          <div class="table flex-1">
            <el-table
              :data="tableData"
              height="100%"
              v-loading="loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <el-table-column type="index" width="60" label="序号">
              </el-table-column>
              <el-table-column prop="phone" label="账号（手机号）">
              </el-table-column>
              <el-table-column prop="name" label="用户名"> </el-table-column>
              <el-table-column prop="gender" label="性别">
                <template slot-scope="scope">
                  <span class="lk c-red" v-if="scope.row.gender == '1'"
                    >男</span
                  >
                  <span class="lk c-green" v-if="scope.row.gender == '2'"
                    >女</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="age" label="年龄"> </el-table-column>
              <el-table-column prop="roleNames" label="角色"> </el-table-column>
              <el-table-column prop="project" label="项目"> </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <span class="lk c-red" v-if="scope.row.status == 2"
                    >已锁定</span
                  >
                  <span class="lk c-green" v-if="scope.row.status == '1'"
                    >正常</span
                  >
                  <span class="lk c-yellow" v-if="scope.row.status == '-1'"
                    >待激活</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="状态变更">
                <template slot-scope="scope">
                  <span
                    class="lk c-main"
                    v-if="scope.row.status == '2'"
                    @click="handleLock(scope.row)"
                    >解锁</span
                  >
                  <span
                    class="lk c-blue"
                    v-if="scope.row.status == '1'"
                    @click="handleLock(scope.row)"
                    >锁定</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <span class="lk c-main" @click="handleEdit(scope.row)"
                    >编辑</span
                  >
                  <span
                    class="lk c-red"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @current-change="handleCurrentChange"
            v-model:current-page="pageInfo.pageNum"
            layout="prev, pager, next, jumper,total"
            :total="total"
            :page-size="pageInfo.pageSize"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 新增用户 -->
    <el-dialog :visible.sync="addUserPop" width="55%">
      <div class="pop pop-adduser">
        <div class="toptt">
          <span class="backbtn">{{ addPopTitle }}</span>
          <i class="el-icon-close close" @click="closeForm"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="userForm" :model="addUserForm" label-width="95px">
            <div class="topbox">
              <!-- <div class="picbox">
                <img :src="imgUrl" class="hdpic" alt="" />
              </div> -->
              <el-upload
                class="avatar-uploader fz"
                :on-exceed="
                  (files, fileList) => handleExceed(files, fileList, 1)
                "
                :class="noneBtnDealImg ? 'disUoloadSty' : ''"
                :action="uploadUrl"
                :fileList="fileList"
                :limit="1"
                :on-success="
                  (res, file, fileList) =>
                    handleAvatarSuccess(res, file, fileList, 'img')
                "
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file, fileList)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </div>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="姓名:" :rules="[{ required: true }]">
                  <el-input
                    v-model="addUserForm.name"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号:" :rules="[{ required: true }]">
                  <el-input
                    v-model="addUserForm.phone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="角色:"
                  :rules="{
                    required: true,
                    message: '角色不能为空',
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="roleIds"
                    multiple
                    placeholder="请选择角色"
                    @change="roleChange"
                  >
                    <el-option
                      v-for="item in roleOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="性别:">
                  <el-select
                    v-model="addUserForm.gender"
                    placeholder="请选择性别"
                  >
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年龄:">
                  <el-input
                    v-model="addUserForm.age"
                    placeholder="请输入年龄"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="密码:" :rules="[{ required: true }]">
                  <el-input
                    v-model="addUserForm.pwd"
                    placeholder="请输入密码"
                    show-password
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="地址:">
                  <el-input
                    v-model="addUserForm.address"
                    placeholder="请输入地址"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="btngroup">
              <button class="ann confirm" @click.prevent="submitForm">
                确定
              </button>
              <button class="ann back" @click="closeForm">返回</button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" modal-append-to-body>
      <div class="pop pop-adduser">
        <div class="toptt">
          <span class="backbtn">图片预览</span>
          <i class="el-icon-close close" @click="dialogVisible = false"></i>
          <div class="dix"></div>
        </div>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import {
  userList,
  addUser,
  editUser,
  delUserById,
  lockUser,
  unLockUser,
} from "@/api/user";
import { roleList } from "@/api/role";
export default {
  data() {
    return {
      form: {
        name: "",
        roleId: "",
        phone: "",
        status: "",
      },
      tableData: [],
      roleOption: [],
      //分页参数
      pageInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      addUserType: 1,
      addPopTitle: "新增用户",
      addUserPop: false,
      loading: false,
      roleIds: [], //角色编号
      roleIdStr: [],
      addUserForm: {
        name: "",
        phone: "",
        roleIds: "",
        gender: "",
        age: "",
        pwd: "",
        headerPic: "",
        address: "",
      },
      uploadUrl: "/apis/002-uploadFileForJson", //上传地址
      imgUrl: "",
      noneBtnDealImg: false,
      imgUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],

      userInfo: {},
      addRolePop: false,
      addRoleForm: {
        mingcheng: "",
        beizhu: "",
        shouquan: [],
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    this.getList();
    this.getRoleList();
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
  },

  computed: {
    //图片上传1张后，隐藏上传框
    uploadDisabled() {
      return this.fileList.length > 0;
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
    handleExceed(files, fileList, num) {
      this.$message.warning(
        `当前限制选择 ${num} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleAvatarSuccess(res, file, fileList, name) {
      this.imgUrl = res.rows[0].url;
      console.log(this.userInfo.id == this.addUserForm.id)
      console.log(res.rows[0].url)
      if (this.userInfo.id == this.addUserForm.id) {
        this.$store.dispatch("setUserImage", res.rows[0].url);
      }
      this.noneBtnDealImg = fileList.length >= 1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList, name) {
      this.dialogImageUrl = "";
      this.fileList = [];
      this.addUserForm.headerPic = "";
      this.noneBtnDealImg = this.fileList.length >= 1;
    },
    /**
     * 分页操作
     * @param {*} val
     */
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
    },
    /**
     * 获取用户列表
     */
    getList() {
      this.loading = true;
      let param = Object.assign(this.pageInfo, this.form, {});
      userList(param).then((res) => {
        if (res.data.code == 0) {
          this.loading = false;
          this.tableData = res.data.rows;
        }
      });
    },
    /**
     * 获取角色列表
     */
    getRoleList() {
      roleList(this.pageInfo).then((res) => {
        if (res.data.code == 0) {
          this.roleOption = res.data.rows;
        }
      });
    },
    /**
     * 新增用户
     */
    addUserInfo() {
      if (this.imgUrl == "") {
        this.$message.warning("请上传图片!");
      } else {
        this.addUserForm.headerPic = this.imgUrl;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      addUser(this.addUserForm).then((res) => {
        if (res.data.code == 0) {
          loading.close();
          this.$message.success(res.data.msg);
          this.closeForm();
          this.getList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    /**
     * 编辑用户
     */
    modifyUserInfo() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.imgUrl == "") {
        this.$message.warning("请上传图片!");
      } else {
        this.addUserForm.headerPic = this.imgUrl;
      }
      editUser(this.addUserForm).then((res) => {
        if (res.data.code == 0) {
          loading.close();
          this.$message.success(res.data.msg);
          this.closeForm();
          this.getList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleLock(row) {
      if (row.status == "1") {
        this.handlelockUser(row.id);
      } else if (row.status == "2") {
        this.handleUnLockUser(row.id);
      } else {
        this.$message.error("此用户暂未激活!");
      }
    },
    handlelockUser(id) {
      let param = {
        ids: id,
      };
      this.loading = true;
      this.$confirm("锁定该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          lockUser(param).then((res) => {
            if (res.data.code == 0) {
              this.loading = false;
              this.$message.success(res.data.msg);
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消锁定",
          });
        });
    },
    handleUnLockUser(id) {
      let param = {
        ids: id,
      };
      this.loading = true;
      this.$confirm("解锁该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unLockUser(param).then((res) => {
            if (res.data.code == 0) {
              this.loading = false;
              this.$message.success(res.data.msg);
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消锁定",
          });
        });
    },
    /**
     * 删除用户
     * @param {*} index
     * @param {*} row
     */
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.deleteUser(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //删除具体用户
    deleteUser(id) {
      let param = {
        ids: String(id),
      };
      this.loading = true;
      delUserById(param).then((res) => {
        if (res.data.code == 0) {
          this.loading = false;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    handleAdd() {
      this.addUserPop = true;
      this.addUserForm.headerPic = "";
      this.addPopTitle = "新增用户";
    },
    handleEdit(row) {
      let arr = []
      this.roleIdStr = row.roleIds.split(",");
      this.roleIdStr.forEach((item) => {
        this.roleIds.push(parseInt(item));
      });
      let obj = {
        url: row.headerPic,
      };
      arr.push(obj)
      this.fileList = arr;
      this.noneBtnDealImg = this.fileList.length >= 1;
      this.userId = row.id;
      this.addUserForm = row;
      this.imgUrl = row.headerPic;
      this.addPopTitle = "编辑用户";
      this.addUserPop = true;
    },
    roleChange(val) {
      this.addUserForm.roleIds = val.join(",");
    },
    /**
     * 提交用户信息
     */
    submitForm() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          if (this.addUserForm.id != null) {
            this.modifyUserInfo();
          } else {
            this.addUserInfo();
          }
        }
      });
    },
    closeForm() {
      this.$refs.userForm.resetFields();
      this.addUserForm = {};
      this.roleIds = [];
      this.addUserPop = false;
    },
  },
};
</script>

<style scoped>
.btn-bar {
  display: flex;
}

.btn-bar .anniu {
  font-size: 14px;
  color: #ccd9e5;
  padding: 0 12px;
  line-height: 38px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  border: none;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  justify-content: center;
}

.btn-bar .anniu::before {
  content: "";
  width: 26px;
  height: 26px;
}

.btn-bar .add {
  background: url(../assets/images/equip_06.png) no-repeat center;
  background-size: 100% 100%;
}

.btn-bar .add::before {
  background: url(../assets/images/icon_05.png) no-repeat center;
  background-size: contain;
}

.btn-bar .role {
  background: url(../assets/images/equip_07.png) no-repeat center;
  background-size: 100% 100%;
}

.btn-bar .role::before {
  background: url(../assets/images/icon_06.png) no-repeat center;
  background-size: contain;
}

.btn-bar .anniu:hover {
  opacity: 0.85;
}

.pop .detail {
  padding-right: 20px;
}

.pop-adduser .topbox {
  padding: 10px 0 35px;
}

.pop-adduser .topbox .fz {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  color: #fff;
}

.pop-adduser .picbox {
  width: 90px;
  height: 90px;
  background: url(../assets/images/set_14.png) no-repeat center;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.pop-adduser .picbox .hdpic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
}

.pop-adduser .btngroup {
  margin-top: 20px;
}

.pop-addrole >>> .el-checkbox {
  display: block;
  font-size: 14px;
  color: #ccd9e5;
}

.pop-addrole .delete {
  font-size: 14px;
  color: #ff1e00;
  cursor: pointer;
}

.pop-addrole .delete .el-icon-delete {
  margin-right: 5px;
}

.pop-addrole .save {
  font-size: 15px;
  color: #fff;
  min-width: 95px;
  height: 40px;
  border: none;
  cursor: pointer;
  margin-left: 20px;
}
::v-deep .disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
