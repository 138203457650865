<template>
  <el-breadcrumb
    class="breadcrumb"
    separator="/"
    style="display: inline-block; margin-left: 10px"
  >
    <!--行内块元素-->
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>{{ currentPathName }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() {
    this.generateBreadcrumb();
  },
  computed: {
    currentPathName() {
      return this.$store.state.currentPathName; //需要监听的数据
    },
  },
  methods: {
    generateBreadcrumb() {
      // 获取当前路由信息
      const matched = this.$route.matched;
      // 初始化面包屑导航数据
      const breadcrumbList = [];
      // 遍历路由信息，生成面包屑导航数据
      matched.forEach((item) => {
        const { meta, name, path } = item;
        if (meta.breadcrumb) {
          breadcrumbList.push({
            name,
            path,
          });
        }
      });
      // 保存面包屑导航数据
      this.breadcrumbList = breadcrumbList;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
